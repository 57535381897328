import { useState, useEffect } from 'react';
import axios from 'axios';

function Dashboard() {
    const [data, setData] = useState()

    useEffect(() => {
        axios.get('https://api.themoviedb.org/3/discover/movie?api_key=c0f2b3829e285f40ea8719b23184af1b&language=fr')
        .then(res => setData(res.data.results))
    }, []);

    return (
        <div>
            {data?.map(e => e.title)}
            <h1>Test CloudFlare</h1>
        </div >
    );
}

export default Dashboard;
